<div class="context-menu-body portal-box portal-box-border"
     [hidden]="!isOpen">
    <ul>
        <ng-container *ngFor="let item of items">
            <div class="separator"
                 *ngIf="item.separator === 'above'"></div>
            <li [class.disabled]="isLoading || (item.disabled$ | async)"
                (click)="itemClick($event, item)">

                <a *ngIf="item.titleKey">
                    <div [translate]="item.titleKey"></div>
                    <div *ngIf="item.subTitleKey"
                         class="subtitle"
                         [translate]="item.subTitleKey"></div>
                </a>
                <ng-container *ngIf="item.template">
                    <ng-container *ngTemplateOutlet="item.template"></ng-container>
                </ng-container>
            </li>
            <div class="separator"
                 *ngIf="item.separator === 'below'"></div>
        </ng-container>
    </ul>
</div>
