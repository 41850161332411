<div class="bg"
     *ngIf="confirmation"
     (click)="cancel()">
     <div class="message-box"
          portalResponsive>

          <div class="message-box-title"
               [translate]="confirmation.titleKey"></div>

          <p [translate]="confirmation.messageKey"
             [translateParams]="confirmation.messageParams">confirmation works!</p>

          <div class="actions">
               <button class="portal-button"
                       (click)="confirm()"
                       [translate]="confirmation.confirmKey"></button>
               <button class="portal-button secondary"
                       (click)="cancel()"
                       [translate]="confirmation.cancelKey"></button>
          </div>
     </div>
</div>
