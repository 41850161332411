import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faAnglesDown,
  faArrowDown,
  faArrowDownAZ,
  faArrowLeft,
  faArrowRotateLeft,
  faArrowUp19,
  faArrowUpAZ,
  faBars,
  faBook,
  faBriefcase,
  faCaretLeft,
  faCaretRight,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCirclePlus,

  faCode,
  faCog,
  faEllipsisV,
  faExclamationTriangle,
  faFilter,
  faForward,
  faIdBadge,
  faLanguage,
  faMagicWandSparkles,
  faMagnifyingGlass,
  faMoon,
  faPalette,
  faPenSquare,
  faPlus,
  faSignOutAlt,
  faSort,
  faTasks,
  faThumbTack,
  faTimes,
  faTimesCircle,
  faUmbrellaBeach,
  faUser,
  faUserTie,
  faUsers,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { faCrosshairs, faHouseFlag } from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowDown19,
  faCalendarLinesPen,
  faColumns3,
  faEmptySet,
  faGripDotsVertical,
  faLockKeyhole,
  faSpinnerThird,
  faUsersMedical
} from '@fortawesome/pro-solid-svg-icons';

// These are the values for IconPrefix:
// "fas" | "far" | "fal" | "fat" | "fad" | "fab" | "fak" ;

export class FontAwesomeService {
  public static Configure(fontAwesomeLibrary: FaIconLibrary) {
    fontAwesomeLibrary.addIcons(
      faExclamationTriangle,
      faTimesCircle,
      faBars,
      faPenSquare,
      faSort,
      faFilter,
      faCog,
      faClock,
      faTasks,
      faTimes,
      faChevronRight,
      faChevronLeft,
      faClock,
      faUser,
      faUsers,
      faBriefcase,
      faEllipsisV,
      faMoon,
      faSignOutAlt,
      faPalette,
      faUmbrellaBeach,
      faMagnifyingGlass,
      faXmark,
      faEmptySet,
      faArrowDown19,
      faArrowUp19,
      faArrowUpAZ,
      faArrowDownAZ,
      faArrowDown,
      faCaretLeft,
      faCaretRight,
      faHouseFlag,
      faLanguage,
      faCode,
      faBook,
      faUsersMedical,
      faLockKeyhole,
      faIdBadge,
      faThumbTack,
      faCirclePlus,
      faSpinnerThird,
      faCalendarLinesPen,
      faUserTie,
      faMagicWandSparkles,
      faArrowLeft,
      faColumns3,
      faArrowRotateLeft,
      faCircleCheck,
      faForward,
      faCrosshairs,
      faGripDotsVertical,
      faAnglesDown,
      faPlus
    ); //https://github.com/FortAwesome/angular-fontawesome, https://stackblitz.com/edit/angular-ivy-7jrcne?file=package.json
  }
}
