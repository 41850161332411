import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '@portal-shared/app/components/base-components/base.component';
import { Toast, ToastType } from '../../models/toast.model';
import { ToastService } from '../../services/toast.service';

@Component({
  standalone: true,
  selector: 'portal-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  imports: [FontAwesomeModule, NgIf, TranslateModule]
})
export class ToastComponent extends BaseComponent implements OnInit {
  public ToastType = ToastType;
  public isShown = true;
  public isSuccess = true;
  public message?: Toast;
  public readonly genericMessageKey = 'core.errors.genericMessage';

  public constructor(private toastService: ToastService) {
    super();
  }

  public ngOnInit(): void {
    this.subscribe(
      this.toastService.observeMessage().subscribe(message => {
        this.message = message;
        this.isShown = true;
        this.isSuccess = message.type === ToastType.success;
      })
    );
  }

  public hide() {
    this.isShown = false;
  }
}
